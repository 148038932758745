var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headers"},[_c('div',{staticClass:"my_header"},[_c('div',{staticClass:"message"},[_c('div',{staticClass:"tit_h2"},[_c('h2',[_vm._v("账户信息")]),(
            _vm.msg_copy.is_bind_wx && _vm.msg_copy.sing_on && !_vm.msg_copy.is_perfect
          )?_c('span',{on:{"click":_vm.editUser}},[_vm._v(" 微信绑定 "),_c('i',{staticClass:"el-icon-edit-outline"})]):_vm._e()]),_c('div',{staticStyle:{"display":"flex"}},[(
            _vm.headshot &&
            _vm.headshot !==
              'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'
          )?_c('img',{attrs:{"src":_vm.headshot,"alt":""}}):_c('img',{attrs:{"src":require("../../../assets/imgs/2.2/user_2x.png"),"alt":""}}),_c('div',{staticClass:"meaasge_box"},[_c('div',{staticClass:"tag"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.name))]),(_vm.id_photo)?_c('el-tag',{staticClass:"accountType",attrs:{"effect":"dark"}},[_vm._v("正式账号")]):_c('el-tag',{staticClass:"accountType",attrs:{"effect":"dark","type":"warning"}},[_vm._v("临时用户")]),(_vm.status == 2 || _vm.status == 4)?_c('span',{on:{"click":_vm.textmsg}},[_c('i',{staticClass:"el-icon-question",staticStyle:{"font-size":"20px","cursor":"pointer"}})]):_vm._e(),(_vm.source_name !== '')?_c('el-tag',{staticClass:"groupName"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.source_name,"placement":"bottom"}},[_c('span',[_vm._v(_vm._s(_vm.source_name))])])],1):_vm._e()],1),_c('div',{staticClass:"email"},[_vm._v("邮箱："+_vm._s(_vm.email_(_vm.email)))]),_c('div',{staticClass:"phone"},[_vm._v("电话："+_vm._s(_vm.phone))])])])]),_c('div',{staticClass:"message"},[_c('h2',[_vm._v("文献信息")]),_c('el-table',{staticClass:"literatureInfo",attrs:{"data":_vm.tableData,"stripe":""}},[_c('el-table-column',{attrs:{"prop":"type","label":"类型"}}),_c('el-table-column',{attrs:{"prop":"中文书","label":"中文书"}}),_c('el-table-column',{attrs:{"prop":"英文书","label":"英文书"}}),_c('el-table-column',{attrs:{"prop":"中文文献","label":"中文文献"}}),_c('el-table-column',{attrs:{"prop":"英文文献","label":"英文文献"}}),_c('el-table-column',{attrs:{"prop":"其他","label":"其他"}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }